<template>
  <div class="lg:w-10/12 xl:w-8/12 mx-auto">
    <page-header title="User settings" subtitle="overview"></page-header>

    <transition name="fade" mode="out-in">
      <router-view />
    </transition>
  </div>
</template>

<script>
export default {

  computed: {},
  methods: {
    isActive (path) {
      let route = this.$route.path.split('/').pop();

      return path === route;
    }
  }
}
</script>

<style>
.settings-card .card-body {
  padding: 0;
}
</style>
